import React, {useState, useEffect} from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { Grid, makeStyles, Typography, Link } from '@material-ui/core'
import { fetchData } from '../utitlities/helperFunctions'
import VideoComponent from '../components/General/VideoComponent'
import TitleComponent from '../components/General/TitleComponent'
import RichTextComponent from '../components/General/RichTextComponent';
import SectionWrapper from '../components/General/SectionWrapper'
import { freeDownloadFormId, freeDownloadFormDivId } from '../utitlities/Configurations'
import Form from '../components/Contact/Form'
import MarketingTestimonials from '../components/Home/MarketingTestimonials'
import { red } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({

  SecondaryLink: {
    backgroundColor: '#f29300',
    color: '#ffffff',
    borderRadius: '10px',
    display: 'block',
    padding: '15px',
    textDecoration: 'none',
    textAlign: 'center',
    fontSize: '1.5em',
    fontWeight: '600',
    '&:hover': {
      color: '#ffffff',
      textDecoration: 'none',
      backgroundColor: '#224674',
    }
  },
  downloadFormSideTitle: {
    fontWeight: 600, 
    paddingTop: '1.5rem', 
    paddingBottom: '1rem', 
    color: '#ffffff',
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem',
    },    
  },

  fm_download_section: {
    position: 'relative',
    overflow: 'hidden',
    paddingBottom: '50px',
    [theme.breakpoints.up('md')]: {
      paddingTop: '135px',
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '135px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '135px',
    }
  },
  download_section_wrap: {
    // border: 'solid 1px green',
    background: '#224674',
    width: '100%',
    float: 'left',
    paddingLeft: '35px',
    paddingRight: '35px',
    borderRadius: '10px',
    [theme.breakpoints.up('md')]: {
    },
    [theme.breakpoints.up('sm')]: {
    },
    [theme.breakpoints.down('xs')]: {
    }
  },
  download_img_section: {
    // border: 'solid 1px red',
    float: 'left',
    [theme.breakpoints.up('sm')]: {
      width: '40%',
      marginTop: '-135px',
    },
    [theme.breakpoints.up('md')]: {
      width: '35%',
      marginTop: '-135px',
    },
    [theme.breakpoints.down('xs')]: {
      // width: '35%',
      marginTop: '-135px',
      width: '100%',
    }
  },
  download_content_section: {
    // border: 'solid 1px blue',
    float: 'left',
    [theme.breakpoints.up('sm')]: {
      width: '65%',
      marginLeft: '-5%',
    },
    [theme.breakpoints.up('md')]: {
      width: '65%',
      marginLeft: '-5%',
    },
    [theme.breakpoints.down('xs')]: {
      // width: '65%',
      // marginLeft: '-5%',
      width: '100%',
    }
  }
}))

const MarketingSecretsPage = ({ data }) => {
  const [AuditData,SetData] = useState()     
    useEffect(() => { 
      fetchData(`marketing-secrets`)
      .then(x => SetData(x) )         
  }, []);

  const classes = useStyles()
  const pageData = AuditData ? AuditData : data.home.edges[0].node
  const bannerImageData = {url: data.bannerImage.edges[0].node.childImageSharp.original.src}
  const pageSeo = pageData.Seo
  return (
    <Layout pageSeo={pageSeo} showFooterBg={false}>      
      <SectionWrapper sectionId='freeMarketing'>
        <Grid container alignItems='center' justifyContent='center'>
            <Grid item md={10}>
                <div style={{
                    paddingBottom: '3rem',
                    paddingTop: '10em'
                }}>
                    <div style={{
                      paddingTop: '0.25em',
                      paddingBottom: '1em'
                    }}>
                      <TitleComponent
                        title={pageData.heading}
                      />
                    </div>

                    <div style={{
                      paddingBottom: '1em'
                    }}>
                      <VideoComponent url={pageData.videoUrl} title='video' />
                    </div>

                    <RichTextComponent
                      styles={{
                        textAlign: "center",
                        paddingTop: '1.5em'
                      }}
                      text={'<h3>' + pageData.subHeading + '</h3>'}
                    />
                </div>
            </Grid>          

            <Grid md={12} item>
              <Grid container spacing={4}>
                {pageData.images && pageData.images.map(item => (
                  <Grid item xs={12} sm={6} md={4} key={item.id}>
                    <img src={item.imageUrl} alt={item.altText} style={{ width: '100%' }} />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item md={12}>
              <RichTextComponent
                styles={{
                  paddingTop: '.75em'
                }}
                text={pageData.details}
              />
            </Grid>
        </Grid>
      </SectionWrapper>
        
      <SectionWrapper sectionId="fm-download_section" className={classes.fm_download_section}>
        <div className={classes.download_section_wrap}> 
          <div className={classes.download_img_section}>
            <img 
              style={{
                maxWidth: '100%'
              }}
              src={ pageData.download_section && pageData.download_section.image_url } 
            /> 
          </div>
          <div className={classes.download_content_section}>
            <Typography className={classes.downloadFormSideTitle} variant="h3"  >
              { pageData.download_section && pageData.download_section.title } 
            </Typography>
            <Form formId={freeDownloadFormId} formDivId={freeDownloadFormDivId} showFullWidth={true} downloadLink={pageData.pdf_download_link}/>
          </div>
        </div>      
      </SectionWrapper>
      
      <MarketingTestimonials
        marketingTestimonials={pageData.testimonials}
        marketingTestimonialVideo={pageData.testimonial_video}
        /> 

      <SectionWrapper sectionId="ext-link-section">
        <Grid container alignItems='center' justifyContent='center'>
          <Grid item md={7} sm={8}
            style={{
              position: 'relative'
            }}
          >
            <Link href="https://shammaskalladath.com/" target='_blank' color="inherit" className={classes.SecondaryLink}>
              Book Consultation With Shammas Kalladath
            </Link>
          </Grid>
        </Grid>
      </SectionWrapper>   
</Layout>
  )
}

export const query = graphql`
query MarketingSecretsQuery {
  home: allApiMarketingSecrets {
    edges {
      node {
          Seo {
            description
            title
          }
          heading          
          subHeading 
          headingHighlights
          details
          images {
            id
            imageUrl
            altText
          }
          testimonials {
            id
            order
            name
            designation
            testimony
            photo_url
          }
          testimonial_video
          download_section{
            title
            image_url
          }
          pdf_download_link
      }
    }
  }
  bannerImage: allFile(
    filter: {sourceInstanceName: {eq: "images"}, name: {eq: "AuditBanner"}}
  ) {
    edges {
      node {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  }
}
`

export default MarketingSecretsPage
